var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.featuredBid && _vm.featuredBid.companyId)?_c('h2',{staticClass:"margin-b-4 font-24 font-weight-bold border-none"},[_vm._v(" Your Quote for "+_vm._s(_vm.featuredBidCompanyDBAName)+" ")]):_vm._e(),_c('h2',{staticClass:"margin-b-4",staticStyle:{"font-size":"24px","border":"none"}},[(!_vm.featuredBid || !_vm.featuredBid.companyId)?_c('b',[_vm._v(_vm._s(_vm.tripHeader))]):_c('span',[_vm._v(_vm._s(_vm.tripHeader))]),_vm._v("   •   "+_vm._s(`ID ${_vm.quote.quoteId}`)+" ")]),(!_vm.areBidsExpired && _vm.bidExpirationDate && _vm.availableBidsExist)?_c('BidListExpirationWarning',{staticClass:"margin-b-4",class:{ 'margin-b-10': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"quote":_vm.quote}}):_vm._e(),(_vm.$cr.breakpoint.smAndUp)?_c('v-layout',{staticStyle:{"margin-top":"2px"},style:({ 'margin-bottom': _vm.featuredBid ? '4px' : '8px'}),attrs:{"row":"","no-gutters":""}},[_c('v-flex',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"xs11":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bidsLoading),expression:"bidsLoading"}],staticClass:"dot-pulse"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bidsLoading),expression:"!bidsLoading"}],staticClass:"refresh-button",on:{"click":function($event){return _vm.$emit('refresh-bid-list')}}},[_vm._v(" Refresh Bids "),_c('CRIcon',{staticStyle:{"padding-left":"4px"},attrs:{"view-box":"0 0 24 24","width":20,"height":20}},[_vm._v(" replay ")])],1)])],1):_vm._e(),(_vm.featuredBid && _vm.featuredBid.companyId)?_c('BidCard',{staticStyle:{"margin-top":"12px","margin-bottom":"12px"},attrs:{"id":"provider-bidcard-featured","preferred":"","featured":"","go-to":{
      name: 'charterup-provider-detail',
      params: {
        quoteId: _vm.quote.quoteId,
        providerId: _vm.featuredBid.companyId,
      },
    },"imgwidth":_vm.$cr.breakpoint.smAndUp ? 220 : 350,"button":_vm.$cr.breakpoint.smAndUp,"stacked":!_vm.$cr.breakpoint.smAndUp,"bid":_vm.featuredBid,"is-quote-expired":_vm.isQuoteExpired},on:{"refresh-bid-list":_vm.refreshBidList}}):_vm._e(),(_vm.charterupChoiceBid)?_c('BidListCharterUpChoice',{attrs:{"bid":_vm.charterupChoiceBid,"quote-id":_vm.quote.quoteId,"is-quote-expired":_vm.isQuoteExpired},on:{"refresh-bid-list":_vm.refreshBidList}}):_vm._e(),(_vm.featuredBid)?_c('h3',{staticStyle:{"margin-top":"16px","margin-bottom":"8px","font-size":"18px"}},[_vm._v(" Other Companies Nearby: ")]):_vm._e(),_vm._l((_vm.sortedQuoteBids),function(bid,i){return _c('BidCard',{key:`${bid.companyId}-${i}`,staticStyle:{"margin-bottom":"12px"},attrs:{"id":`provider-bidcard-${i}`,"preferred":"","go-to":{
      name: 'charterup-provider-detail',
      params: { quoteId: _vm.quote.quoteId, providerId: bid.companyId },
    },"imgwidth":_vm.$cr.breakpoint.smAndUp ? 220 : 350,"button":_vm.$cr.breakpoint.smAndUp,"stacked":!_vm.$cr.breakpoint.smAndUp,"bid":bid,"is-quote-expired":_vm.isQuoteExpired},on:{"refresh-bid-list":_vm.refreshBidList}})}),(_vm.unavailableBids.length !== 0)?_c('h2',{staticStyle:{"font-weight":"600"}},[_vm._v(" Unavailable Companies ")]):_vm._e(),_vm._l((_vm.unavailableBids),function(bid,i){return _c('BidCard',{key:`${bid.companyId}-${i}`,attrs:{"id":`unavailable-bidcard-${i}`,"imgwidth":_vm.$cr.breakpoint.smAndUp ? 220 : 350,"button":_vm.$cr.breakpoint.smAndUp,"stacked":!_vm.$cr.breakpoint.smAndUp,"bid":bid,"is-quote-expired":_vm.isQuoteExpired},on:{"refresh-bid-list":_vm.refreshBidList}})}),(_vm.bids.length === 0)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" Oops! No bus companies available here. ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }