
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import TripCard from '@/components/charterup/TripCard.vue'
import { quoteDetailV2 } from '@/services/charterup/quotes'
import * as logger from '@/utils/logger'
import NotFound from '@/components/charterup/NotFound.vue'
import BidList from './QuoteDetail/BidList.vue'
import CharterUPValueProps from '@/components/charterup/CharterUPValueProps.vue'
import { ValuePropKey } from '@/utils/enum'

@Component({
  components: {
    TripCard,
    NotFound,
    BidList,
    CharterUPValueProps,
  },
})
export default class QuoteDetail extends Vue {
  @Prop({ type: Object, required: true }) readonly quote: any
  @Prop({ type: Boolean, required: false, default: false })
  readonly isQuoteExpired: boolean

  @Watch('quote', { immediate: true })
  handler(quoteDataValue) {
    // If quote changes, then this is part of the quote form.
    if (!quoteDataValue || !quoteDataValue.quoteId) {
      return
    }
    this.getBidsForQuoteId(quoteDataValue.quoteId)
  }

  quoteData = null
  featuredBid = null
  notFound = false
  message = 'This page does not exist or some other error has occurred.'
  loading = false
  ValuePropKey = ValuePropKey

  async mounted() {
    this.loading = true
    await this.refreshBidList()
    this.loading = false
  }

  async getBidsForQuoteId(quoteId) {
    try {
      const response = await quoteDetailV2(quoteId)
      const newData = response.data.data
      this.quoteData = newData
    } catch (e: any) {
      this.notFound = true
      this.message = e.response?.data.message
      logger.error(e)
    }

    const featuredArray = this.quoteData.bids?.filter((bid) => {
      return bid.featured
    })

    if (featuredArray.length === 1) {
      this.featuredBid = featuredArray[0]
    }
    this.quoteData.bids = this.quoteData.bids?.filter((bid) => {
      return !bid.featured
    })
  }

  async refreshBidList() {
    const quoteId =
      this.$route.name === 'charterup-quote-detail' &&
      this.$route.params?.quoteId
        ? this.$route.params.quoteId
        : this.quote?.quoteId
    try {
      await this.getBidsForQuoteId(quoteId)
    } catch (error) {
      console.error('Error fetching bids:', error)
    }

    if (
      this.quote?.pricingMethod === 'category' &&
      this.quote?.quoteId &&
      this.quoteData?.bids[0]?.companyId
    ) {
      await this.$router.push({
        name: 'charterup-checkout',
        params: {
          quoteId: this.quote.quoteId,
          providerId: this.quoteData.bids[0].companyId,
        },
      })
    }
  }
}
