<template>
  <div>
    <h2 v-if="featuredBid && featuredBid.companyId"
      class="margin-b-4 font-24 font-weight-bold border-none">
      Your Quote for {{featuredBidCompanyDBAName}}
    </h2>
    <h2 class="margin-b-4" style="font-size: 24px; border: none;">
      <b v-if="!featuredBid || !featuredBid.companyId">{{ tripHeader }}</b>
      <span v-else>{{ tripHeader }}</span>
      &nbsp;&nbsp;•&nbsp;&nbsp;
      {{ `ID ${quote.quoteId}` }}
    </h2>
    <BidListExpirationWarning
      v-if="!areBidsExpired && bidExpirationDate && availableBidsExist"
      :quote="quote"
      class="margin-b-4"
      :class="{ 'margin-b-10': $vuetify.breakpoint.mdAndDown }"
    />
    <v-layout
      v-if="$cr.breakpoint.smAndUp"
      row
      no-gutters
      style="margin-top: 2px;"
      :style="{ 'margin-bottom': featuredBid ? '4px' : '8px'}"
    >
      <v-flex xs11 style="display: flex; justify-content: end">
        <div v-show="bidsLoading" class="dot-pulse"></div>
        <div

          v-show="!bidsLoading"
          class="refresh-button"
          @click="$emit('refresh-bid-list')"
        >
          Refresh Bids
          <CRIcon
            view-box="0 0 24 24"
            :width="20"
            :height="20"
            style="padding-left: 4px"
          >
            replay
          </CRIcon>
        </div>
      </v-flex>
    </v-layout>
    <BidCard
      v-if="featuredBid && featuredBid.companyId"
      id="provider-bidcard-featured"
      preferred
      featured
      :go-to="{
        name: 'charterup-provider-detail',
        params: {
          quoteId: quote.quoteId,
          providerId: featuredBid.companyId,
        },
      }"
      style="margin-top: 12px; margin-bottom: 12px"
      :imgwidth="$cr.breakpoint.smAndUp ? 220 : 350"
      :button="$cr.breakpoint.smAndUp"
      :stacked="!$cr.breakpoint.smAndUp"
      :bid="featuredBid"
      :is-quote-expired="isQuoteExpired"

      @refresh-bid-list="refreshBidList"
    />
    <BidListCharterUpChoice
      v-if="charterupChoiceBid"
      :bid="charterupChoiceBid"
      :quote-id="quote.quoteId"
      :is-quote-expired="isQuoteExpired"
      @refresh-bid-list="refreshBidList"
    />
    <h3
      v-if="featuredBid"
      style="margin-top: 16px; margin-bottom: 8px; font-size: 18px"
    >
      Other Companies Nearby:
    </h3>
    <BidCard
      v-for="(bid, i) in sortedQuoteBids"
      :id="`provider-bidcard-${i}`"
      :key="`${bid.companyId}-${i}`"
      preferred
      :go-to="{
        name: 'charterup-provider-detail',
        params: { quoteId: quote.quoteId, providerId: bid.companyId },
      }"
      style="margin-bottom: 12px"
      :imgwidth="$cr.breakpoint.smAndUp ? 220 : 350"
      :button="$cr.breakpoint.smAndUp"
      :stacked="!$cr.breakpoint.smAndUp"
      :bid="bid"
      :is-quote-expired="isQuoteExpired"
      @refresh-bid-list="refreshBidList"
    />
    <h2 v-if="unavailableBids.length !== 0" style="font-weight: 600">
      Unavailable Companies
    </h2>
    <BidCard
      v-for="(bid, i) in unavailableBids"
      :id="`unavailable-bidcard-${i}`"
      :key="`${bid.companyId}-${i}`"
      :imgwidth="$cr.breakpoint.smAndUp ? 220 : 350"
      :button="$cr.breakpoint.smAndUp"
      :stacked="!$cr.breakpoint.smAndUp"
      :bid="bid"
      :is-quote-expired="isQuoteExpired"
      @refresh-bid-list="refreshBidList"
    />

    <div v-if="bids.length === 0" style="text-align: center">
      Oops! No bus companies available here.
    </div>
  </div>
</template>

<script>
import BidCard from '@/components/charterup/BidCard.vue'
import BidListCharterUpChoice from './BidListCharterUpChoice.vue'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import { datetimeToShortLocalizedDateTime } from '@/utils/time'
import { formatQuotePickupDestinationText } from '@/utils/string'
import BidListExpirationWarning from './BidListExpirationWarning.vue'
import { PartnerTypeId } from '@/utils/enum'

export default {
  components: {
    BidCard,
    BidListExpirationWarning,
    BidListCharterUpChoice,
  },
  props: {
    quote: {
      type: Object,
      default: null,
    },
    featuredBid: {
      type: Object,
      default: null,
    },
    isQuoteExpired: {
      type: Boolean,
      default: false,
    },
    bidsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    customerId() {
      return this.quote?.customer?.customerId
    },
    charterupChoiceBid() {
      const bids = this.quote?.bids || []
      return bids.find((bid) => bid.isCharterUPChoice)
    },
    bids() {
      return this.quote?.bids || []
    },
    areBidsExpired() {
      if (this.quote.expirationDate === null) {
        return false
      }

      if (this.isQuoteExpired) {
        return true
      }

      const secondsToExpire = DateTime.fromISO(this.quote.expirationDate)
        .diffNow()
        .as('seconds')
      return secondsToExpire <= 0
    },
    bidExpirationDate() {
      return datetimeToShortLocalizedDateTime(this.quote?.expirationDate)
    },
    sortedQuoteBids() {
      let bids = deepClone(this.bids)
      bids = bids
        .filter((bid) => bid.partnerTypeId !== PartnerTypeId.Bronze && !bid.soldOut)
        // We only want to filter the CharterUp Choice option out if we are going
        // to display it separately (ie. if the feature flag is enabled)
        .filter((bid) => bid.companyId !== this.charterupChoiceBid?.companyId)
      return bids.sort((a, b) => a.totalAmount - b.totalAmount)
    },
    unavailableBids() {
      const bids = deepClone(this.bids)
      return bids.filter(
        (bid) => bid.partnerTypeId === PartnerTypeId.Bronze || bid.soldOut
      )
    },
    bidsExist() {
      return this.bids?.length !== 0 || !!this.featuredBid
    },
    availableBidsExist() {
      return (
        this.bidsExist && this.bids?.length !== this.unavailableBids?.length
      )
    },
    tripHeader() {
      return formatQuotePickupDestinationText(this.quote)
    },
    featuredBidCompanyDBAName() {
      return this.featuredBid?.companyDBAName
    },
  },
  methods: {
    datetimeToShortLocalizedDateTime,
    refreshBidList() {
      this.$emit('refresh-bid-list')
    },
  },
}
</script>
<style lang="scss" scoped>
.refresh-button {
  color: $primary;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}

.dot-pulse {
  position: relative;
  top: 10px;
  left: -10020px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
</style>
