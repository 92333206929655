
import { ValuePropKey } from '@/utils/enum'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import CharterUPValueProp from '@/components/charterup/CharterUPValueProp.vue'

@Component({
name: 'CharterUPValueProps',
components: { CharterUPValueProp }
})
export default class CharterUPValueProps extends Vue {
@Prop({ type: Array, default: [] }) readonly valuePropKeys: typeof ValuePropKey[]
@Ref() readonly rowContainer!: HTMLDivElement

get hasOddNumber() {
  return this.valuePropKeys.length % 2 === 1
}

get isMobile() {
  return this.$vuetify.breakpoint.xsOnly
}

isMobileAndMoreThanOneKey() {
  return this.isMobile && this.valuePropKeys?.length > 1
}

doesNotHaveOddNumberAndIndexNotZero(index: number) {
  return !this.hasOddNumber && index !== 0
}

}
