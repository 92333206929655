<template>
  <div
    class="d-flex border-radius-8 border-1 border-solid border-border-gray padding-a-4"
  >
    <div class="max-w-24 margin-r-1">
      <CRIcon view-box="0 0 24 24" class="w-24 h-24">price_expire</CRIcon>
    </div>
    <div>
      <h4
        class="font-16 line-height-1 margin-b-1"
        :style="{ 'font-weight': 600 }"
      >
        Prices and availability may change until booked.
      </h4>
      <span class="font-14">
        This quote will be active until {{ formattedExpirationDate }}.
      </span>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    quote: {
      type: Object,
      default: null,
    },
  },
  computed: {
    expirationDate() {
      return this.quote ? this.quote.expirationDate : ''
    },
    expirationTimezone() {
      return this.quote ? this.quote.expirationTimezone : ''
    },
    formattedExpirationDate() {
      if (!this.expirationDate || !this.expirationTimezone) {
        return ''
      }

      const expirationDateWithTimezone = DateTime.fromISO(this.expirationDate, {
        zone: this.expirationTimezone,
      })

      const localTimezone = DateTime.local().zoneName
      const expirationDateInLocalTimezone = expirationDateWithTimezone.setZone(
        localTimezone
      )

      return expirationDateInLocalTimezone.toFormat('MMM d, yyyy, h:mm a ZZZZ')
    },
  },
}
</script>
