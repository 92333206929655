
import  { ValuePropKey } from '@/utils/enum'
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoModal from './InfoModal.vue'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import {
  bookingProtectionModalDetail,
  liveSupportModalDetail,
  qualifiedOperatorsModalDetail,
  vehicleLocationTrackingModalDetail
} from '@/utils/infoModal'

@Component({ components: { InfoModal } })
export default class CharterUPValueProp extends Vue {
  @Prop({ type: String, required: true }) readonly valuePropKey: typeof ValuePropKey

  get label(): string {
    return this.$vuetify.breakpoint.mdAndUp ? this.valuePropDetail.label : this.valuePropDetail.shortLabel
  }

  get icon(): string {
    return this.valuePropDetail?.icon?.name || ''
  }

  get valuePropDetail(): InfoModalDetail {
    const key = this.valuePropKey

    const map: Record<ValuePropKey, InfoModalDetail> = {
      [ValuePropKey.BookingProtection]: bookingProtectionModalDetail,
      [ValuePropKey.Tracking]: vehicleLocationTrackingModalDetail,
      [ValuePropKey.LiveSupport]: liveSupportModalDetail,
      [ValuePropKey.QualifiedOperators]: qualifiedOperatorsModalDetail,
    }

    return map[key as unknown as string]
  }
}
