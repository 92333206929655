
import { Vue, Component, Prop } from 'vue-property-decorator'
import BidCard from '@/components/charterup/BidCard.vue'
import InfoModal from '@/components/charterup/InfoModal.vue'
import { charterupChoiceModalDetail } from '@/utils/infoModal'

@Component({
  name: 'BidListCharterUpChoice',
  components: {
    BidCard,
    InfoModal,
  },
})
export default class BidListCharterUpChoice extends Vue {
  @Prop({ type: Object, required: true }) bid!: any
  @Prop({ type: String, required: true }) quoteId!: string
  @Prop({ type: Boolean, required: true }) isQuoteExpired!: boolean
  @Prop({ type: Boolean, required: true }) isHasTrackingEnabled!: boolean

  modalOpen = false
  charterupChoiceModalDetail = charterupChoiceModalDetail

  refreshBidList(): void {
    this.$emit('refresh-bid-list')
  }

  get operatorName(): string {
    return this.bid.companyDBAName
  }
}
